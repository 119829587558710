@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css";
@import '~@angular/material/theming';
@include mat-core();


html,
body {
  height: 100%;
  width: 99%;
}
:root {
  --primary-blue: #337ab7;
  --primary-green: #377215;
  --primary-red: #bc5a3c;
  --secundary: #bebebe;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #888;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--primary-blue);
  outline: 1px solid slategrey;
}

::-webkit-scrollbar-thumb:hover {
  background: #225581;
}

::ng-deep .mat-select-arrow {
  opacity: 0;
}

button {
  text-shadow: 0px 1px black;
}

.snack-bar {
  background: #337ab7;
  color:white;
}


// Can be used to make dark mode
// // Light theme
// $light-primary: mat-palette($mat-amber);
// $light-accent:  mat-palette($mat-pink, A200, A100, A400);
// $light-theme:   mat-light-theme($light-primary, $light-accent);

// @media (prefers-color-scheme: light) {
//   @include angular-material-theme($light-theme);
// }

// // Dark theme
// $dark-primary: mat-palette($mat-blue-grey);
// $dark-accent:  mat-palette($mat-amber, A200, A100, A400);
// $dark-warn:    mat-palette($mat-deep-orange);
// $dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

// @media (prefers-color-scheme: dark) {
//   @include angular-material-theme($dark-theme);
// }
